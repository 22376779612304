export const patternMails = (emails) => {
    let array = [];
    emails.forEach((email) => {
        array.push({
            cdEmail: email.cdEmail,
            nmEmail: email.nmTipo !== 'CRIACAO_ESCOLA_NOVA' ? email.escola ? email.escola.nmEmail : 'olimpiadamirim@obmep.org.br' : 'olimpiadamirim@obmep.org.br',
            nmTipo: email.nmTipo,
            cdEscola: email.escola ? email.escola.cdMecEscola : "--",
            nmEscola: email.escola ? email.escola.nmEscola : "--",
        });
    })
    return array;
}

export const patternTypeMail = type => type === 'CRIACAO_ESCOLA_NOVA' ? 'Criação de Nova Escola' 
                                        : type === 'AVALIACAO_ESCOLA_NOVA' ? 'Avaliação de Nova Escola' 
                                        : type === 'VALIDACAO_CONVENIO_NECESSIDADE_PAGAMENTO' ? 'Necessidade de Pagamento - Escola Privada que Informou ser Convêniada' 
                                        : type === 'ALERTA_CENTRAL_TRATAMENTO_CONVENIO' ? 'Alerta Para Central - Escolas com Tratamento de Convênio para ser Realizado' 
                                        : type === 'COMUNICACAO_STATUS_OBJETO_POSTAL' ? 'Comunicação para Escolas sobre Status de Objeto Postal' 
                                        : type === 'LEMBRETE_ESCOLA_PAGAMENTO' ? 'Lembrete para Escolas Privadas sobre Pagamento'
                                        : type === 'LEMBRETE_ESCOLA_PRIMEIRO_DIA_PAGAMENTO' ? 'Lembrete para Escolas Privadas sobre Primeiro dia de Pagamento' 
                                        : type === 'LEMBRETE_ESCOLA_ULTIMO_DIA_PAGAMENTO' ? 'Lembrete para Escolas Privadas sobre Último dia de Pagamento'
                                        : type === 'DECLARACAO_ESCOLA_CONVENIADA_TOTAL' ? 'Solicitação de Declaração para Escolas que se Declararam Conveniadas Total'
                                        : type === 'AVALIACAO_ESCOLA_CONVENIO_TOTAL' ? 'Avaliação de Escola com Convênio Total'
                                        : type === 'LEMBRETE_ESCOLA_LANCAMENTO_NOTAS_FASE_2_E_REALIZAREM_PREMIACAO' ? 'Lembrete para Escolas Realizarem o Lançamento de Notas da Fase 2 e Realizarem Premiação'
                                        : type === 'LEMBRETE_ESCOLA_RESOLUCAO_DESEMPATE' ? 'Lembrete para Escolas Resolverem os Desempates'
                                        : '';

export const optionsType = [
    { value: 'CRIACAO_ESCOLA_NOVA', label: 'Criação de Nova Escola' },
    { value: 'AVALIACAO_ESCOLA_NOVA', label: 'Avaliação de Nova Escola' },
    { value: 'VALIDACAO_CONVENIO_NECESSIDADE_PAGAMENTO', label: 'Necessidade de Pagamento - Escola Privada que Informou ser Convêniada' },
    { value: 'ALERTA_CENTRAL_TRATAMENTO_CONVENIO', label: 'Alerta Para Central - Escolas com Tratamento de Convênio para ser Realizado' },
    { value: 'COMUNICACAO_STATUS_OBJETO_POSTAL', label: 'Comunicação para Escolas sobre Status de Objeto Postal'},
    { value: 'LEMBRETE_ESCOLA_PAGAMENTO', label: 'Lembrete para Escolas Privadas sobre Pagamento'},
    { value: 'LEMBRETE_ESCOLA_PRIMEIRO_DIA_PAGAMENTO', label: 'Lembrete para Escolas Privadas sobre Primeiro dia de Pagamento' },
    { value: 'LEMBRETE_ESCOLA_ULTIMO_DIA_PAGAMENTO', label: 'Lembrete para Escolas Privadas sobre Último dia de Pagamento'},
    { value: 'DECLARACAO_ESCOLA_CONVENIADA_TOTAL', label: 'Solicitação de Declaração para Escolas que se Declararam Conveniadas Total'},
    { value: 'AVALIACAO_ESCOLA_CONVENIO_TOTAL', label: 'Avaliação de Escola com Convênio Total'},
    { value: 'LEMBRETE_ESCOLA_LANCAMENTO_NOTAS_FASE_2_E_REALIZAREM_PREMIACAO', label: 'Lembrete para Escolas Realizarem o Lançamento de Notas da Fase 2 e Realizarem Premiação'},
    { value: 'LEMBRETE_ESCOLA_RESOLUCAO_DESEMPATE', label: 'Lembrete para Escolas Resolverem os Desempates'},
];

export const patternSendSchool = (objetos) => {
    let array = [];
    objetos.forEach((obj) => {
        if (obj.emailEscola) 
            array.push({
                id: obj.cdEscola,
                email: obj.emailEscola,
                codigoMec: obj.codigoMec,
                nome: obj.nomeEscola,
                numeroSedex: obj.numero,
                ultimoStatus: obj.status,
                enderecoUnidadeRetirada: obj.enderecoUnidadeRetirada,
                logradouroDestino: obj.enderecoEntrega.nmEndereco,
                bairroDestino: obj.enderecoEntrega.nmBairro,
                numeroDestino: obj.enderecoEntrega.nmNumero,
                cepDestino: obj.enderecoEntrega.nmCep,
                cidadeDestino: obj.enderecoEntrega.municipio.nmMunicipio,
                ufDestino: obj.enderecoEntrega.municipio.uf.cdUf,
            });
    })
    return array;
}

export const patternSendSchoolWithResponsible = (objetos) => {
    let array = [];
    objetos.forEach((obj) => {
        if (obj.emailEscola) 
            array.push({
                id: obj.cdEscola,
                email: obj.emailEscola,
                codigoMec: obj.codigoMec,
                nome: obj.nomeEscola,
                numeroSedex: obj.numero,
                ultimoStatus: obj.status,
                enderecoUnidadeRetirada: obj.enderecoUnidadeRetirada,
                logradouroDestino: obj.enderecoEntrega.nmEndereco,
                bairroDestino: obj.enderecoEntrega.nmBairro,
                numeroDestino: obj.enderecoEntrega.nmNumero,
                cepDestino: obj.enderecoEntrega.nmCep,
                cidadeDestino: obj.enderecoEntrega.municipio.nmMunicipio,
                ufDestino: obj.enderecoEntrega.municipio.uf.cdUf,
            });

        if (obj.emailResponsavel) 
            array.push({
                id: obj.cdEscola,
                email: obj.emailResponsavel,
                codigoMec: obj.codigoMec,
                nome: obj.nomeEscola,
                numeroSedex: obj.numero,
                ultimoStatus: obj.status,
                enderecoUnidadeRetirada: obj.enderecoUnidadeRetirada,
                logradouroDestino: obj.enderecoEntrega.nmEndereco,
                bairroDestino: obj.enderecoEntrega.nmBairro,
                numeroDestino: obj.enderecoEntrega.nmNumero,
                cepDestino: obj.enderecoEntrega.nmCep,
                cidadeDestino: obj.enderecoEntrega.municipio.nmMunicipio,
                ufDestino: obj.enderecoEntrega.municipio.uf.cdUf,
            });
    })
    return array;
}